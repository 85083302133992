<template>
    <div>
        <div class="lg:w-4/12 md:w-10/12 mt-4 w-full text-center p-2 mx-auto">
            <router-link to="/" class="px-2 text-[14px] font-bold text-[#785e60] cursor-pointer">HOME</router-link>
            <router-link to="/privacy" class="px-2 text-[14px] font-bold text-[#785e60] cursor-pointer">PRIVACY</router-link>
            <a href="mailto:Info@pomodoroy.com" class="px-2 text-[14px] font-bold text-[#785e60] cursor-pointer">CONTACT</a>
            <router-link :to="{name: 'simple-page', params: { simple: true }}" class="px-2 text-[14px] font-bold text-[#785e60] cursor-pointer">SIMPLE PAGE</router-link>
        </div>

        <div v-if="false" class="flex justify-center items-center gap-4 mt-4 mb-6">
            <img
                src="../assets/facebook.png"
                class="w-10 h-10 bg-gray-400 p-1 rounded-full"
                alt=""
            />

            <img
                src="../assets/twitter.png"
                class="w-10 h-10 bg-gray-400 p-1 rounded-full"
                alt=""
            />

            <img
                src="../assets/stripe-climate-badge.png"
                class="w-10 h-10 bg-gray-400 p-1 rounded-full"
                alt=""
            />
        </div>

        <div v-if="false" class="text-center my-4 text-[#785e60]">
        Made by <span class="text-[#d95550] font-bold">Sagheer Hussain</span>
        </div>

        <div class="text-center my-4 text-[#785e60] text-xs">
        ©Pomodoroy 2025. All Rights Reserved.
        </div>
    </div>
</template>